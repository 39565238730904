import React from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { AuthorSection } from '../shared/Shared';
import { generateMetadata } from '../../metadata/siteMetadata';
import { getAuthorSection } from '../../metadata/authorMetadata';
import { PageMetadata } from '../shared/PageMetadata';
import { ARTICLE_DATES } from '../../constants/dates';

const pageMetadata = {
  title: 'a2z Radiology AI: Our Story and Mission',
  description: 'Learn about a2z Radiology AI\'s journey, our mission to transform medical imaging with AI, and our commitment to improving patient care through innovative technology.',
  type: 'article',
  publishedDate: ARTICLE_DATES.A2Z_STORY,
  modifiedDate: ARTICLE_DATES.A2Z_STORY
};

const A2zstory: React.FC = () => {
  const metadata = generateMetadata({
    title: 'Launching a2z Radiology AI: Vision for a World Where no Disease is Missed',
    description: 'a2z Radiology AI emerges from stealth, introducing AI-powered analytics for radiologists with a2z-1 for abdominal-pelvis CT interpretation.',
    type: 'article',
    publishedDate: ARTICLE_DATES.A2Z_STORY,
    modifiedDate: ARTICLE_DATES.A2Z_STORY
  });

  const authorSection = getAuthorSection('October 7, 2024');

  return (
    <div className="min-h-screen bg-white dark:bg-dark text-gray-900 dark:text-gray-100">
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta property="og:title" content={metadata.openGraph.title} />
        <meta property="og:description" content={metadata.openGraph.description} />
        <meta property="og:image" content={metadata.openGraph.image} />
        <meta name="twitter:card" content={metadata.twitter.card} />
        <link rel="canonical" href="https://a2zradiology.ai/news/a2z-radiology-ai-out-of-stealth" />
        <script type="application/ld+json">
          {JSON.stringify(metadata.jsonLd)}
        </script>
      </Helmet>

      <PageMetadata {...pageMetadata} />

      <main className="max-w-4xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <motion.h1
          className="text-4xl sm:text-5xl font-bold mb-8 text-primary dark:text-accent"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          {metadata.openGraph.title}
        </motion.h1>

        <AuthorSection {...authorSection} />

        <motion.div
          className="text-md sm:text-xl space-y-6 text-gray-800 dark:text-gray-200"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <p>
            Today, a2z Radiology AI emerges from stealth mode, introducing our mission to create an AI-powered safety net for radiologists. The name <strong>"a2z"</strong> embodies our commitment to comprehensive analysis, covering everything from <em>A to Z</em> in medical imaging. We're proud to launch <strong>a2z-1</strong>, our groundbreaking first product designed to empower radiologists with unparalleled insights in abdominal-pelvis CT interpretation.
          </p>
          <p>
            We founded a2z Radiology AI this year with a vision to develop AI that serves as a reliable ally for radiologists, providing valuable insights without disrupting established workflows. This goal, shaped by years of collaboration with radiologists and experience in technology, has driven our work during our stealth period.
          </p>
          <p>
            Our launch comes at a pivotal moment in the field of radiology AI. Recent advancements in AI have opened new possibilities for scalable, multi-disease analysis in radiology image interpretation. We've utilized these advancements to build custom AI models that can simultaneously analyze multiple conditions, a capability that distinguishes a2z-1 in the landscape of radiology AI.
          </p>
          <p>
            Notably, a2z-1 is the first of its kind in the history of AI in radiology to tackle multi-disease abdomen-pelvis CT interpretation. This exam type is one of the most common in radiology practices, yet it has long challenged AI due to its complexity and the wide range of potential findings. By focusing on this critical area, we're addressing a significant need in the field and providing valuable insights with cutting-edge AI.
          </p>
          <p>
            Our focus with a2z-1 on analytical insights maximizes near-term impact in radiology practices. a2z-1 analyzes completed reports and images for 21 specific findings in abdomen-pelvis CT scans, providing valuable insights for quality improvement, continuous learning, and benchmarking. This approach enhances radiology departments' analytical capabilities, supporting comprehensive abdomen-pelvis CT analysis and fostering excellence in radiological practice.
          </p>
          <p>
            During our stealth phase, we've collaborated extensively with radiologists and early partners to develop a solution that prioritizes real-world performance. We've validated a2z-1 with data acquired from three distinct health systems, conducted thorough subgroup analyses, and optimized our analytics to provide meaningful insights while avoiding information overload. Our innovative approach to model training and annotation has allowed us to create a tool that's not only conceptually promising but also robust and ready for immediate implementation.
          </p>
          <p>
            While a2z-1 marks our public debut, it's only the beginning. We're already developing next-generation models to further enhance our analytics capabilities for abdominal-pelvis CT interpretation.
          </p>
          <p>
            As we make our work public, we're reaching out to forward-thinking health systems to join us in raising the standard of care. If you lead a radiology team and want to enhance your analytics capabilities, reduce reporting variability, and foster continuous improvement, we invite you to connect with us.
          </p>
          <p>
            The time has come to create a future where comprehensive analytics support radiologists in providing the best possible care.
          </p>
        </motion.div>

        {/* Important Notice */}
        <motion.div
          className="mt-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
        </motion.div>

        <motion.div
          className="mt-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 1.8 }}
        >
          <Link to="/" className="text-primary dark:text-accent hover:underline">
            &larr; Back to Home
          </Link>
        </motion.div>
      </main>
    </div>
  );
};

export default A2zstory;
