import React from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { ShinyButton } from '../shared/Shared';
import { AuthorSection } from '../shared/Shared';
import { generateMetadata } from '../../metadata/siteMetadata';
import { PageMetadata } from '../shared/PageMetadata';
import { ARTICLE_DATES } from '../../constants/dates';

const pageMetadata = {
  title: 'New Research Paper: External Validation Study Released',
  description: 'Our comprehensive external validation study demonstrating exceptional performance across 21 conditions in abdomen-pelvis CT is now available. Read the full paper and analysis.',
  type: 'article',
  publishedDate: ARTICLE_DATES.PREPRINT,
  modifiedDate: ARTICLE_DATES.PREPRINT,
  authors: [
    {
      name: 'Pranav Rajpurkar',
      url: 'https://www.linkedin.com/in/pranavrajpurkar/'
    },
    {
      name: 'Samir Rajpurkar',
      url: 'https://www.linkedin.com/in/samir-rajpurkar-79291396/'
    }
  ]
};

const A2Z1Preprint: React.FC<{ openCalendly: () => void }> = ({ openCalendly }) => {
  const metadata = generateMetadata({
    title: 'a2z-1 Clinical Validation Analysis',
    description: 'Read our comprehensive clinical validation analysis of a2z-1, demonstrating exceptional performance across 21 conditions in abdomen-pelvis CT interpretation.',
    type: 'article',
    publishedDate: '2024-12-17T13:00:00-04:00'
  });

  const images = [
    {
      original: '/a2z-1-paper/2412.12629v1-images-0.jpg',
      thumbnail: '/a2z-1-paper/2412.12629v1-images-0.jpg',
    },
    {
      original: '/a2z-1-paper/2412.12629v1-images-1.jpg',
      thumbnail: '/a2z-1-paper/2412.12629v1-images-1.jpg',
    },
    {
      original: '/a2z-1-paper/2412.12629v1-images-2.jpg',
      thumbnail: '/a2z-1-paper/2412.12629v1-images-2.jpg',
    },
    {
      original: '/a2z-1-paper/2412.12629v1-images-3.jpg',
      thumbnail: '/a2z-1-paper/2412.12629v1-images-3.jpg',
    },
    {
      original: '/a2z-1-paper/2412.12629v1-images-4.jpg',
      thumbnail: '/a2z-1-paper/2412.12629v1-images-4.jpg',
    },
    {
      original: '/a2z-1-paper/2412.12629v1-images-5.jpg',
      thumbnail: '/a2z-1-paper/2412.12629v1-images-5.jpg',
    },
    {
      original: '/a2z-1-paper/2412.12629v1-images-6.jpg',
      thumbnail: '/a2z-1-paper/2412.12629v1-images-6.jpg',
    },
    {
      original: '/a2z-1-paper/2412.12629v1-images-7.jpg',
      thumbnail: '/a2z-1-paper/2412.12629v1-images-7.jpg',
    },
    {
      original: '/a2z-1-paper/2412.12629v1-images-8.jpg',
      thumbnail: '/a2z-1-paper/2412.12629v1-images-8.jpg',
    },
    {
      original: '/a2z-1-paper/2412.12629v1-images-9.jpg',
      thumbnail: '/a2z-1-paper/2412.12629v1-images-9.jpg',
    },
    {
      original: '/a2z-1-paper/2412.12629v1-images-10.jpg',
      thumbnail: '/a2z-1-paper/2412.12629v1-images-10.jpg',
    },
    {
      original: '/a2z-1-paper/2412.12629v1-images-11.jpg',
      thumbnail: '/a2z-1-paper/2412.12629v1-images-11.jpg',
    },
    {
      original: '/a2z-1-paper/2412.12629v1-images-12.jpg',
      thumbnail: '/a2z-1-paper/2412.12629v1-images-12.jpg',
    },
    {
      original: '/a2z-1-paper/2412.12629v1-images-13.jpg',
      thumbnail: '/a2z-1-paper/2412.12629v1-images-13.jpg',
    },
    {
      original: '/a2z-1-paper/2412.12629v1-images-14.jpg',
      thumbnail: '/a2z-1-paper/2412.12629v1-images-14.jpg',
    },
  ];

  return (
    <div className="min-h-screen bg-white dark:bg-dark text-gray-900 dark:text-gray-100">
      <Helmet>
        <title>a2z-1 Clinical Validation Analysis | a2z Radiology AI</title>
        <meta
          name="description"
          content="Read our comprehensive clinical validation analysis of a2z-1, demonstrating exceptional performance across 21 conditions in abdomen-pelvis CT interpretation."
        />
        <meta
          property="og:title"
          content="a2z-1 Clinical Validation Analysis | a2z Radiology AI"
        />
        <meta
          property="og:description"
          content="Read our comprehensive clinical validation analysis of a2z-1, demonstrating exceptional performance across 21 conditions in abdomen-pelvis CT interpretation."
        />
        <meta property="og:image" content={metadata.openGraph.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="a2z-1 Clinical Validation Analysis | a2z Radiology AI"
        />
        <meta
          name="twitter:description"
          content={metadata.twitter.description}
        />
        <meta name="twitter:image" content={metadata.twitter.image} />
      </Helmet>

      <main className="max-w-4xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <motion.h1
          className="text-4xl sm:text-5xl font-bold mb-8 text-primary dark:text-accent"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Announcing Our Clinical Validation Paper: a2z-1 Performance Analysis Across 21 Conditions
        </motion.h1>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <AuthorSection date="December 17, 2024" />
        </motion.div>

        <motion.div
          className="text-md sm:text-xl space-y-6 text-gray-800 dark:text-gray-200"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <p>
            Today, we're excited to announce the release of our first original research article, now available as a preprint on{' '}
            <a
              href="https://arxiv.org/abs/2412.12629"
              className="text-primary dark:text-accent hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              ArXiv
            </a>. This article, titled "a2z-1 for Multi-Disease Detection in Abdomen-Pelvis CT: External Validation and Performance Analysis Across 21 Conditions," compiles and formalizes our previously shared validation results into a comprehensive academic analysis.
          </p>

          <p>
            While we've previously shared aspects of these results through our blog posts, this research article, authored by Pranav Rajpurkar, Julian N. Acosta, Siddhant Dogra, Jaehwan Jeong, Deepanshu Jindal, Michael Moritz, and Samir Rajpurkar, provides a complete academic treatment of our findings. It brings together our external validation results, subgroup analyses, and radiologist agreement studies into a single, peer-reviewable academic work.
          </p>

          <h2 className="text-2xl font-bold mt-8 mb-4">Key Findings</h2>
          <p>
            Our research article provides a formal analysis of results we've previously shared, along with additional insights:
          </p>
          <ul className="list-disc pl-6 space-y-4 mt-4">
            <li>
              <strong>Exceptional Performance:</strong> Our model achieved an average AUC of 0.931 across 21 conditions, with external validation confirming consistent results (AUC 0.923) across different healthcare systems.
            </li>
            <li>
              <strong>Critical Condition Detection:</strong> Particularly strong performance in detecting time-sensitive conditions, including small bowel obstruction (AUC 0.958) and acute pancreatitis (AUC 0.961).
            </li>
            <li>
              <strong>Robust Generalization:</strong> Demonstrated consistent performance across three distinct health systems, validating the model's ability to generalize across different clinical settings.
            </li>
            <li>
              <strong>Comprehensive Analysis:</strong> Detailed investigation of model behavior across diverse patient demographics, imaging protocols, and varied clinical scenarios.
            </li>
          </ul>

          <h2 className="text-2xl font-bold mt-8 mb-4">Validation Methodology</h2>
          <p>
            Our validation study stands out for its rigorous methodology and broad scope. We conducted extensive evaluations across multiple health systems, analyzed performance across diverse patient demographics and imaging protocols, and performed detailed investigations of model behavior in challenging cases.
          </p>

          <div className="my-12">
            <ImageGallery
              items={images}
              showPlayButton={false}
              showFullscreenButton={true}
              showThumbnails={true}
              showBullets={false}
              showNav={true}
              thumbnailPosition="bottom"
              additionalClass="paper-gallery"
            />
          </div>

          <h2 className="text-2xl font-bold mt-8 mb-4">Looking Forward</h2>
          <p>
            This validation study represents a crucial step in advancing the field of AI-assisted radiology. The results demonstrate the technical capabilities of a2z-1 and its potential to enhance radiologist workflow and analytical capabilities.
          </p>
          <p className="mt-4">
            We invite you to read the complete article on{' '}
            <a
              href="https://arxiv.org/abs/2412.12629"
              className="text-primary dark:text-accent hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              ArXiv
            </a>{' '}
            and join us in advancing the field of AI-assisted radiology.
          </p>

          <div className="bg-blue-100 dark:bg-blue-900 p-6 rounded-lg mt-12">
            <h2 className="text-2xl font-bold mb-4">Join Our Design Partner Program</h2>
            <p className="mb-6">
              Experience a2z-1's validated performance firsthand and contribute to advancing AI in radiology. Connect with us to learn how we can enhance your practice's analytical capabilities.
            </p>
            <ShinyButton onClick={openCalendly}>
              Schedule a Demo
            </ShinyButton>
          </div>

          <div className="mt-12">
            <Link to="/" className="text-primary dark:text-accent hover:underline block">
              &larr; Back to Home
            </Link>
          </div>
        </motion.div>
      </main>

      <PageMetadata {...pageMetadata} />
    </div>
  );
};

export default A2Z1Preprint; 