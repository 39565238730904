import React from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ShinyButton } from '../shared/Shared';
import { generateMetadata } from '../../metadata/siteMetadata';
import { getAuthorSection } from '../../metadata/authorMetadata';
import { ARTICLE_DATES } from '../../constants/dates';

const FTMention: React.FC<{ openCalendly: () => void }> = ({ openCalendly }) => {
  const metadata = generateMetadata({
    title: 'a2z Radiology AI Mentioned in Financial Times',
    description: 'a2z Radiology AI was mentioned in a Financial Times article about AI in healthcare and the future of medical imaging diagnostics.',
    type: 'article',
    publishedDate: ARTICLE_DATES.FT_MENTION,
    modifiedDate: ARTICLE_DATES.FT_MENTION
  });

  const authorSection = getAuthorSection('November 7, 2024');

  return (
    <div className="min-h-screen bg-white dark:bg-dark text-gray-900 dark:text-gray-100">
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta property="og:title" content={metadata.openGraph.title} />
        <meta property="og:description" content={metadata.openGraph.description} />
        <meta property="og:image" content={metadata.openGraph.image} />
        <meta name="twitter:card" content={metadata.twitter.card} />
        <link rel="canonical" href="https://a2zradiology.ai/news/financial-times-mention" />
        <script type="application/ld+json">
          {JSON.stringify(metadata.jsonLd)}
        </script>
      </Helmet>

      <main className="max-w-4xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <motion.h1
          className="text-4xl sm:text-5xl font-bold mb-8 text-primary dark:text-accent"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          {metadata.openGraph.title}
        </motion.h1>

        <motion.div
          className="flex items-center space-x-4 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <img
            src={authorSection.companyLogo}
            alt={authorSection.companyName}
            className="w-10 h-10 rounded-full object-cover"
          />
          <div>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              {authorSection.date}
            </p>
          </div>
        </motion.div>

        <motion.div
          className="prose dark:prose-invert max-w-none"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <p className="text-lg">
            We're excited to share that our co-founder Dr. Pranav Rajpurkar was quoted in a Financial Times article about AI in healthcare. The article by Sarah Neville, titled "The doctors pioneering the use of AI to improve outcomes for patients," explores how artificial intelligence is transforming healthcare through faster diagnostics, more targeted treatment, and better communication.
          </p>

          <div className="my-8 p-8 bg-gradient-to-br from-blue-50 to-gray-100 dark:from-gray-800 dark:to-gray-900 rounded-lg shadow-lg">
            <div className="space-y-6">
              <p className="leading-relaxed">
                Pranav Rajpurkar — assistant professor of biomedical informatics at Harvard Medical School and co-founder of a2z Radiology AI, which has produced an AI model for abdominal and pelvic CAT scans — believes failure rates may, one day, be eradicated altogether. He says:
              </p>
              
              <div className="my-8 mx-4 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md border-l-4 border-primary dark:border-accent">
                <blockquote className="text-2xl font-serif italic text-primary dark:text-accent">
                  "I think there [can be] a world in which, because of AI, we don't make medical errors [and] in which no disease is missed."
                </blockquote>
              </div>

              <p className="leading-relaxed">
                However, while the technology is already contributing to the earlier detection of time-sensitive conditions, it is not yet "making people faster at what they do," he cautions.
              </p>

              <p className="leading-relaxed">
                His research mission is to build what are called generalist medical AI models that will be capable of achieving "the full spectrum of tasks that doctors can do in medical image interpretation."
              </p>

              <p className="leading-relaxed">
                While, currently, AI can detect lung nodules on chest X-rays, or lesions on mammograms, for example, "the expert still has to do 200 to 400 other things as part of the interpretation and we don't [yet] have algorithms that can do that," points out Rajpurkar.
              </p>

              <p className="leading-relaxed">
                "The efficiency value proposition is one that still we have not delivered on in AI, but one that I think is something we're on the cusp of being able to do with upcoming technology advances."
              </p>
            </div>
          </div>

          <p>
            Dr. Rajpurkar's vision of a world where "no disease is missed" perfectly embodies a2z's mission to create an AI-powered safety net for radiologists. While we're still working towards making AI more efficient in daily clinical practice, this vision drives our commitment to developing comprehensive AI solutions that can help eliminate medical errors and ensure no critical finding goes undetected.
          </p>

          <div className="bg-blue-100 dark:bg-blue-900 p-6 rounded-lg mt-12">
            <h2 className="text-2xl font-bold mb-4">Read the Full Article</h2>
            <p className="mb-6">
              Read the complete article on Financial Times:{' '}
              <a 
                href="https://www.ft.com/content/2fd63023-ec0a-421c-9abb-b6c8000b3b51"
                className="text-primary dark:text-accent hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                The doctors pioneering the use of AI to improve outcomes for patients
              </a>
            </p>
          </div>

          <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg mt-12">
            <h2 className="text-2xl font-bold mb-4">Learn More About a2z-1</h2>
            <p className="mb-6">
              Interested in seeing how our AI technology can enhance your radiology practice? Schedule a demo to learn more about our validated solutions.
            </p>
            <ShinyButton onClick={openCalendly}>
              Schedule a Demo
            </ShinyButton>
          </div>

          <div className="mt-12">
            <Link to="/" className="text-primary dark:text-accent hover:underline block">
              &larr; Back to Home
            </Link>
          </div>
        </motion.div>
      </main>
    </div>
  );
};

export default FTMention; 