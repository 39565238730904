import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaUserMd } from 'react-icons/fa';

import { Case } from './CaseViewer';

interface ImageShowcaseProps {
  isLoading?: boolean;
  error?: string | null;
  disableBackground?: boolean;
}

const allImages = [
  '/examples/detections/WLV_visualized_heart_coronary_artery_calcification.png',
  '/examples/detections/WLV_stomach_hiatal_hernia.png',
  '/examples/detections/N43_stomach_hiatal_hernia.png',
  '/examples/detections/KU4_spleen_splenomegaly.png',
  '/examples/detections/K0Z_visualized_heart_coronary_artery_calcification.png',
  '/examples/detections/K0Z_stomach_hiatal_hernia.png',
  '/examples/detections/K0Z_gallbladder_cholecystitis.png',
  '/examples/detections/EM9_pancreas_acute_pancreatitis.png',
  '/examples/detections/0A1_peritoneum_free_air.png',
];

const ExpertCommentary: React.FC<{ commentary: string }> = ({ commentary }) => {
  return (
    <div className="p-3 sm:p-4">
      <div className="flex items-center mb-2 sm:mb-4">
        <FaUserMd className="mr-2 text-lg sm:text-xl text-white" />
        <h3 className="text-base sm:text-lg font-semibold text-white">
          Expert Commentary
        </h3>
      </div>
      <p className="text-xs sm:text-sm leading-relaxed whitespace-pre-wrap text-gray-200">
        {commentary || 'No commentary available.'}
      </p>
    </div>
  );
};

const ImageShowcase: React.FC<ImageShowcaseProps> = ({ isLoading, error, disableBackground }) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [commentaries, setCommentaries] = useState<Record<string, string>>({});
  const [cases, setCases] = useState<Record<string, Case>>({});
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState<string | null>(null);
  const [primaryImages, setPrimaryImages] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setDataLoading(true);
        const [commentariesResponse, casesResponse] = await Promise.all([
          fetch('/examples/data/commentaries.json'),
          fetch('/examples/data/cases.json')
        ]);
        
        if (!commentariesResponse.ok || !casesResponse.ok) {
          throw new Error('Failed to fetch data');
        }

        const commentariesData = await commentariesResponse.json();
        const casesData = await casesResponse.json() as Record<string, Case>;
        
        setCommentaries(commentariesData);
        setCases(casesData);

        // Get primary findings for each case
        const primaryFindings = new Map<string, { condition: string; confidence: number }>();
        
        Object.entries(casesData).forEach(([caseId, caseData]) => {
          let highestConfidence = -1;
          let primaryCondition = '';
          
          if (caseData && typeof caseData === 'object' && 'findings' in caseData) {
            Object.entries(caseData.findings).forEach(([condition, confidence]) => {
              if (typeof confidence === 'number' && confidence > highestConfidence) {
                highestConfidence = confidence;
                primaryCondition = condition;
              }
            });
          }
          
          if (primaryCondition) {
            primaryFindings.set(caseId, { 
              condition: primaryCondition,
              confidence: highestConfidence 
            });
          }
        });

        // Filter images to only include those matching primary findings
        const filtered = allImages.filter(imagePath => {
          const caseId = imagePath.split('/').pop()?.split('_')[0];
          if (!caseId) return false;
          
          const primaryFinding = primaryFindings.get(caseId);
          if (!primaryFinding) return false;
          
          // Check if the image path contains the primary condition
          const conditionInPath = primaryFinding.condition.split('/')[1]; // Get the condition part after the slash
          return imagePath.toLowerCase().includes(conditionInPath.toLowerCase());
        });

        setPrimaryImages(filtered);
      } catch (error) {
        setDataError(error instanceof Error ? error.message : 'An error occurred');
      } finally {
        setDataLoading(false);
      }
    };

    fetchData();
  }, []);

  const getCommentaryForImage = (imagePath: string) => {
    const caseId = imagePath.split('/').pop()?.split('_')[0];
    return caseId ? commentaries[caseId] : null;
  };

  const getCaseNameForImage = (imagePath: string) => {
    const caseId = imagePath.split('/').pop()?.split('_')[0];
    return caseId && cases[caseId] ? cases[caseId].name : null;
  };

  if (isLoading || dataLoading) {
    return (
      <div className="w-full py-4 sm:py-8">
        <div className="max-w-full mx-auto px-2 sm:px-4">
          <div className="flex space-x-3 sm:space-x-6 overflow-x-auto pb-4 sm:pb-6">
            {[...Array(4)].map((_, index) => (
              <div
                key={index}
                className="w-[280px] sm:w-[400px] lg:w-[500px] h-[280px] sm:h-[400px] lg:h-[500px] flex-shrink-0 bg-gray-200 dark:bg-gray-800 rounded-lg sm:rounded-xl animate-pulse"
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error || dataError) {
    return (
      <div className="text-red-500 p-4 dark:text-red-300">
        Error: {error || dataError}
      </div>
    );
  }

  return (
    <div className={`w-full py-4 sm:py-8 ${!disableBackground ? 'bg-gradient-to-b from-light to-gray-50 dark:from-dark dark:to-gray-900' : ''}`}>
      <div className="max-w-full mx-auto">
        <motion.div 
          className="flex space-x-3 sm:space-x-6 overflow-x-auto pb-4 sm:pb-6 px-2 sm:px-4 snap-x snap-mandatory scrollbar-none"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {primaryImages.map((image, index) => (
            <motion.div
              key={image}
              className="relative w-[280px] sm:w-[400px] lg:w-[500px] h-[280px] sm:h-[400px] lg:h-[500px] flex-shrink-0 overflow-hidden rounded-lg sm:rounded-xl bg-black/5 dark:bg-white/5 backdrop-blur-sm cursor-pointer snap-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.05 }}
              whileHover={{ scale: 1.01 }}
              onClick={() => setSelectedIndex(selectedIndex === index ? null : index)}
              onHoverStart={() => setHoveredIndex(index)}
              onHoverEnd={() => setHoveredIndex(null)}
            >
              <motion.img
                src={image}
                alt="Medical scan visualization"
                className="w-full h-full object-cover relative z-0"
                initial={{ scale: 1.05 }}
                animate={{ 
                  scale: (hoveredIndex === index || selectedIndex === index) ? 1.08 : 1.05,
                  filter: (hoveredIndex === index || selectedIndex === index) ? 'brightness(0.9)' : 'brightness(1)'
                }}
                transition={{ duration: 0.2 }}
              />
              <motion.div
                className="absolute inset-0 bg-gradient-to-t from-gray-900/95 via-gray-900/70 to-gray-900/20 z-10"
                initial={{ opacity: 0 }}
                animate={{ opacity: (hoveredIndex === index || selectedIndex === index) ? 1 : 0 }}
                transition={{ duration: 0.2 }}
              />
              {(hoveredIndex === index || selectedIndex === index) && (
                <motion.div
                  className="absolute bottom-0 left-0 right-0 p-3 sm:p-6 overflow-y-auto max-h-full z-20"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="bg-gray-900/90 backdrop-blur-sm rounded-lg p-3 sm:p-4 mb-2 sm:mb-4 ring-1 ring-white/10">
                    <h3 className="text-base sm:text-xl font-semibold text-white">
                      {getCaseNameForImage(image)}
                    </h3>
                  </div>
                  <div className="bg-gray-900/90 backdrop-blur-sm rounded-lg ring-1 ring-white/10">
                    <ExpertCommentary commentary={getCommentaryForImage(image) || ''} />
                  </div>
                </motion.div>
              )}
            </motion.div>
          ))}
        </motion.div>
        
        {/* Scroll hint */}
        <div className="mt-2 sm:mt-4 text-center text-xs sm:text-sm text-gray-500 dark:text-gray-400">
          Scroll horizontally to see more examples →
        </div>
      </div>
    </div>
  );
};

export default ImageShowcase; 