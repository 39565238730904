import { siteMetadata } from './siteMetadata';

export interface AuthorMetadata {
  name: string;
  title: string;
  image: string;
  linkedin: string;
}

export const getAuthorSection = (date: string, updatedDate?: string) => {
  return {
    date,
    updatedDate,
    authors: siteMetadata.authors,
    companyLogo: siteMetadata.companyInfo.logo.square,
    companyName: siteMetadata.companyInfo.name,
    companyUrl: siteMetadata.companyInfo.url
  };
};

export const getAuthorJsonLd = (authors: AuthorMetadata[]) => {
  return authors.map(author => ({
    '@type': 'Person',
    name: author.name,
    url: author.linkedin
  }));
}; 