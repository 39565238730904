import React from 'react';

interface AuthorSectionProps {
  date: string;
  updatedDate?: string;
  showCompanyLogo?: boolean;
}

const AuthorSection: React.FC<AuthorSectionProps> = ({
  date,
  updatedDate,
  showCompanyLogo = true,
}) => {
  return (
    <div className="flex items-center space-x-4 mb-8">
      {/* Company Logo */}
      {showCompanyLogo && (
        <img
          src="/assets/logos/a2z-logo-square-500px.png"
          alt="a2z Radiology AI"
          className="w-10 h-10 rounded-full object-cover"
        />
      )}

      {/* Author Name, Date, and Image */}
      <div className="flex items-center space-x-4">
        <img
          src="/team/PranavRajpurkar.jpg"
          alt="Pranav Rajpurkar"
          className="w-12 h-12 rounded-full object-cover"
        />
        <img
          src="/team/SamirRajpurkar.jpg"
          alt="Samir Rajpurkar"
          className="w-12 h-12 rounded-full object-cover"
        />
        <div>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            By{' '}
            <a
              href="https://a2zradiology.ai"
              className="text-primary dark:text-accent hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              a2z Radiology AI
            </a>{' '}
            on {date}
          </p>
          <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
            Connect with{' '}
            <a
              href="https://www.linkedin.com/in/pranavrajpurkar/"
              className="text-primary dark:text-accent hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Pranav Rajpurkar
            </a>{' '}
            and{' '}
            <a
              href="https://www.linkedin.com/in/samir-rajpurkar-79291396/"
              className="text-primary dark:text-accent hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Samir Rajpurkar
            </a>{' '}
            on LinkedIn
            {updatedDate && (
              <span className="block mt-1">Updated on {updatedDate}</span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AuthorSection; 