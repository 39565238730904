// src/components/home/index.tsx
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { PageMetadata } from '../shared/PageMetadata';
import { generateMetadata } from '../../metadata/siteMetadata';
import {
  AlertTriangle,
  Award,
  BookOpen,
  Users,
  BrainCircuit,
  BarChart2,
  UsersRound,
  GraduationCap,
  Newspaper,
  ClipboardCheck,
  ArrowRight
} from 'lucide-react';
import {
  ConditionPerformanceChart,
  PerformanceComparisonChart,
  SubgroupChart,
} from '../shared/Charts';
import { ShinyButton, SidebarNav } from '../shared/Shared';
import { Element } from 'react-scroll';
import { ARTICLE_DATES } from '../../constants/dates';
import ImageShowcase from '../shared/ImageShowcase';

// Utility function to format date as "Month Day, Year"
const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
};

// Text Content
const content = {
  hero: {
    title: "a2z-1: The First Multi-Disease AI Model for Abdomen-Pelvis CT",
    subtitle: "Unlike traditional single-disease AI solutions, a2z-1 is a groundbreaking generalist model capable of detecting 21 actionable conditions in abdomen-pelvis CT scans—from common findings like appendicitis to critical conditions like aortic aneurysms.",
    buttonText: "Partner With Us",
  },
  impact: {
    title: "Prototype Capabilities",
    description:
      "As a design partner, you'll get early access to explore these emerging capabilities:",
    points: [
      {
        title: "Experimental Analysis Pipeline",
        description:
          "Test our prototype pipeline that analyzes abdomen-pelvis CT scans across 21 findings. Help us validate and refine the model's analytical approach.",
        icon: BarChart2,
      },
      {
        title: 'Collaborative Development',
        description:
          'Work directly with our research team to shape feature development and identify new use cases based on your real-world needs.',
        icon: UsersRound,
      },
      {
        title: 'Early Performance Insights',
        description:
          'Get visibility into preliminary performance metrics and help establish meaningful benchmarks for clinical implementation.',
        icon: GraduationCap,
      },
    ],
  },
  howItWorks: {
    title: 'Why Multi-Disease Detection Matters',
    description: 'Our approach transforms abdomen-pelvis CT interpretation:',
    steps: [
      {
        title: 'Beyond Single-Disease AI',
        description:
          'While existing AI tools focus on one condition, a2z-1 simultaneously detects 21 actionable findings in abdomen-pelvis CT—from appendicitis and hepatic steatosis to unruptured aortic aneurysms.',
      },
      {
        title: 'Comprehensive Safety Net',
        description:
          'By analyzing multiple conditions at once, a2z-1 acts as an AI-driven safety net, ensuring no critical finding goes undetected in this challenging modality.',
      },
      {
        title: 'Validated Performance',
        description:
          'Validated across 14,000 scans from three health systems, achieving AUC scores of 0.855-0.970 for all 21 conditions.',
      }
    ],
  },
  validation: {
    title: 'Early Validation Results',
    points: [
      {
        title: 'Prototype Performance',
        description:
          'Initial testing shows promising results across 21 findings, with preliminary metrics ranging from 0.855 to 0.970. Help us validate these results in real-world settings.',
        icon: Award,
      },
      {
        title: 'Ongoing Research',
        description:
          'Active research partnerships are helping us understand model behavior across different clinical contexts and patient populations.',
        icon: AlertTriangle,
      },
      {
        title: 'Collaborative Refinement',
        description:
          'Work with our team to identify edge cases, refine detection criteria, and optimize for your specific needs.',
        icon: Users,
      },
    ],
  },
  takeNextStep: {
    title: "Join Our Design Partner Program",
    description:
      "We're looking for innovative radiology departments to help shape the future of AI in medical imaging. As a design partner, you'll get:",
    userTypes: [
      {
        title: 'Early Access & Integration',
        description:
          'Get first access to our technology and work with our team to integrate a2z-1 into your existing workflows for testing and validation.',
      },
      {
        title: 'Direct Product Influence',
        description:
          'Shape our development roadmap through regular feedback sessions and help us refine features based on real-world clinical needs.',
      },
      {
        title: 'Research Collaboration',
        description:
          `Participate in validation studies and contribute to scientific publications demonstrating a2z-1's performance across diverse clinical settings.`,
      },
    ],
    buttonText: "Apply for Partnership",
  },
  importantNotice: {
    title: 'Prototype Status Notice',
    description:
      'a2z-1 is a prototype tool in active development, intended for research and validation purposes only. It is not FDA-cleared and not for clinical use or diagnosis. Design partners will be involved in non-clinical testing and feature development only.',
  },
};

// Add this to the content object
const validationPages = [
  {
    title: 'Preliminary Results',
    description: 'Early performance data from our prototype testing across different settings.',
    icon: Award,
    path: '/validation/external'
  },
  {
    title: 'Error Analysis',
    description: 'Help us understand and improve model behavior in challenging cases.',
    icon: AlertTriangle,
    path: '/validation/miss-analysis'
  },
  {
    title: 'Research Directions',
    description: 'Explore ongoing investigations and future development priorities.',
    icon: Users,
    path: '/validation/subgroup'
  }
];

// Hero Section Component
const HeroSection = ({ openCalendly }: { openCalendly: () => void }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <section
      className="relative overflow-hidden flex items-center py-8 px-4 sm:px-6 lg:px-8 min-h-[60vh]"
      id="home"
    >
      <div className="relative z-10 max-w-4xl mx-auto flex flex-col lg:flex-row items-center">
        {/* Text Content */}
        <div className="w-full lg:w-1/2 lg:pr-8">
          <motion.h1
            className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-4 text-primary dark:text-accent text-left"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            {content.hero.title}
          </motion.h1>
          <motion.p
            className="text-lg sm:text-xl mb-8 text-gray-700 dark:text-gray-300 text-left"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            {content.hero.subtitle}
          </motion.p>

          {/* Button Container */}
          <div className="flex justify-start">
            <ShinyButton
              onClick={openCalendly}
              className="sm:mx-0 mx-auto mb-4 lg:mb-0"
            >
              {content.hero.buttonText}
            </ShinyButton>
          </div>
        </div>

        {/* Visual Content */}
        <div className="w-full lg:w-1/2 mt-8 lg:mt-0">
          <motion.div
            className="relative w-full max-w-3xl mx-auto"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{
              opacity: isImageLoaded ? 1 : 0,
              scale: isImageLoaded ? 1 : 0.8,
            }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            <img
              src="/examples/detections/EM9_pancreas_acute_pancreatitis.png"
              alt="AI-Generated Insight: Abdomen-Pelvis CT"
              className="rounded-lg shadow-2xl w-full object-contain-custom"
              onLoad={() => setIsImageLoaded(true)}
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

// Real-World Impact Section
const RealWorldImpactSection: React.FC = () => {
  const impactPoints = content.impact.points;

  return (
    <section id="impact" className="bg-light dark:bg-dark">
      {/* Main content with max-width */}
      <div className="py-10 px-4 sm:px-8 max-w-4xl mx-auto">
        <motion.h2
          className="text-3xl sm:text-4xl font-bold mb-6 text-gray-900 dark:text-gray-200 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          {content.impact.title}
        </motion.h2>
        <motion.p
          className="text-md sm:text-xl mb-6 text-gray-700 dark:text-gray-300 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          {content.impact.description}
        </motion.p>
        <ul className="space-y-6 mb-12">
          {impactPoints.map((item, index) => (
            <motion.li
              key={index}
              className="flex items-start"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.4 + index * 0.2 }}
            >
              <item.icon className="mr-4 h-6 w-6 text-primary flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-200">
                  {item.title}
                </h3>
                <p className="text-gray-700 dark:text-gray-300">
                  {item.description}
                </p>
              </div>
            </motion.li>
          ))}
        </ul>
      </div>
      
      {/* Full-width ImageShowcase section */}
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="px-4 sm:px-8"
        >
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-gray-200 mb-6">
            Example Detections
          </h3>
          <ImageShowcase disableBackground />
        </motion.div>
      </div>
    </section>
  );
};

// How a2z-1 Works Section
const HowA2z1WorksSection = () => {
  const steps = content.howItWorks.steps;

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <section id="how-it-works" className="bg-light dark:bg-dark">
      <div className="py-10 px-4 sm:px-8 max-w-4xl mx-auto">
        <motion.h2
          className="text-3xl sm:text-4xl font-bold mb-6 text-gray-900 dark:text-gray-200 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          {content.howItWorks.title}
        </motion.h2>
        <motion.p
          className="text-md sm:text-xl mb-8 text-gray-700 dark:text-gray-300 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          {content.howItWorks.description}
        </motion.p>
        {/* Workflow Steps */}
        <motion.div
          className="flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {steps.map((step, index) => (
            <motion.div
              key={index}
              className="flex-1 flex items-start"
              variants={itemVariants}
            >
              {/* Numbered Badge */}
              <div className="relative mr-4">
                <div className="flex items-center justify-center h-10 w-10 bg-primary text-white rounded-full text-lg font-bold">
                  {index + 1}
                </div>
              </div>
              {/* Step Details */}
              <div>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-200">
                  {step.title}
                </h3>
                <p className="text-gray-700 dark:text-gray-300">
                  {step.description}
                </p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

// Scientific Validation Section with Tabbed Interface
const ScientificValidationSection = () => {
  const validationPoints = content.validation.points;
  const [activeTab, setActiveTab] = useState(0);

  // Define the tabs array with proper typing
  interface Tab {
    label: string;
    component: JSX.Element;
  }

  const tabs: Tab[] = [
    {
      label: 'Condition Performance',
      component: <ConditionPerformanceChart />,
    },
    {
      label: 'Performance Comparison',
      component: <PerformanceComparisonChart />,
    },
    {
      label: 'Subgroup Analysis',
      component: <SubgroupChart />
    },
  ];

  return (
    <section id="validation" className="bg-light dark:bg-dark">
      <div className="py-10 px-4 sm:px-8 max-w-4xl mx-auto">
        <motion.h2
          className="text-3xl sm:text-4xl font-bold mb-6 text-gray-900 dark:text-gray-200 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          {content.validation.title}
        </motion.h2>

        {/* Validation Page Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
          {validationPages.map((page, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 * index }}
            >
              <Link
                to={page.path}
                className="block h-full bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 p-6"
              >
                <page.icon className="h-12 w-12 text-primary mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-200 mb-2">
                  {page.title}
                </h3>
                <p className="text-gray-700 dark:text-gray-300">
                  {page.description}
                </p>
                <div className="mt-4 text-primary dark:text-accent font-semibold flex items-center">
                  Learn More
                  <svg
                    className="w-4 h-4 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>

        {/* Rest of the existing validation content */}
        <div className="space-y-8">
          {validationPoints.map((item, index) => (
            <motion.div
              key={index}
              className="flex items-start"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 * index }}
            >
              <item.icon className="mr-4 h-8 w-8 text-primary flex-shrink-0" />
              <div>
                <h3 className="text-2xl font-semibold text-gray-900 dark:text-gray-200">
                  {item.title}
                </h3>
                <p className="text-gray-700 dark:text-gray-300">
                  {item.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Tabbed Interface */}
        <div className="mt-12">
          {/* Tab Headers */}
          <div className="flex border-b border-gray-300 dark:border-gray-700">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`py-2 px-4 -mb-px font-semibold text-sm transition-colors duration-300 focus:outline-none ${
                  activeTab === index
                    ? 'border-b-2 border-primary text-primary'
                    : 'text-gray-600 dark:text-gray-300 hover:text-primary'
                }`}
                onClick={() => setActiveTab(index)}
                aria-selected={activeTab === index}
                role="tab"
                aria-controls={`tabpanel-${index}`}
                id={`tab-${index}`}
              >
                {tab.label}
              </button>
            ))}
          </div>

          {/* Tab Panels */}
          <div className="mt-4">
            {tabs.map((tab, index) => (
              <AnimatePresence key={index}>
                {activeTab === index && (
                  <motion.div
                    id={`tabpanel-${index}`}
                    role="tabpanel"
                    aria-labelledby={`tab-${index}`}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                  >
                    {tab.component}
                  </motion.div>
                )}
              </AnimatePresence>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

// Take the Next Step Section
const TakeNextStepSection = ({
  openCalendly,
}: {
  openCalendly: () => void;
}) => (
  <section id="take-the-next-step" className="relative overflow-hidden">
    <div className="blur-behind">
      <div className="py-16 px-4 sm:px-8 max-w-4xl mx-auto text-left">
        <motion.h2
          className="gradient-text text-4xl sm:text-5xl font-extrabold mb-6"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          {content.takeNextStep.title}
        </motion.h2>
        <motion.p
          className="text-lg sm:text-2xl mb-12 text-gray-700 dark:text-gray-300"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          {content.takeNextStep.description}
        </motion.p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {content.takeNextStep.userTypes.map((type, index) => (
            <motion.div
              key={type.title}
              className="glass-card p-6 hover-lift"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.1 * (index + 1) }}
            >
              <div className="bg-primary/10 dark:bg-primary/20 p-3 rounded-xl glow w-fit mb-4">
                {index === 0 && <Users className="h-8 w-8 text-primary dark:text-accent" />}
                {index === 1 && <BrainCircuit className="h-8 w-8 text-primary dark:text-accent" />}
                {index === 2 && <BookOpen className="h-8 w-8 text-primary dark:text-accent" />}
              </div>
              <h3 className="text-lg font-semibold gradient-text mb-3">
                {type.title}
              </h3>
              <p className="text-gray-700 dark:text-gray-300">
                {type.description}
              </p>
            </motion.div>
          ))}
        </div>
        <motion.div
          className="flex justify-center mt-12"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <ShinyButton onClick={openCalendly} className="!py-3 !text-lg !px-8 glow">
            {content.takeNextStep.buttonText}
          </ShinyButton>
        </motion.div>
      </div>
    </div>
  </section>
);

// Related Content Section
const RelatedContent = () => {
  const articles = [
    {
      title: "External Validation Results: Performance Across 21 Conditions",
      description: "Comprehensive analysis of a2z-1's performance across three health systems, involving over 14,000 CT studies and demonstrating consistent results for critical findings.",
      path: "/validation/external",
      date: ARTICLE_DATES.EXTERNAL_VALIDATION,
      icon: ClipboardCheck,
      category: 'validation'
    },
    {
      title: "New Research Paper: External Validation Study Released",
      description: "Our comprehensive external validation study demonstrating exceptional performance across 21 conditions in abdomen-pelvis CT is now available. Read the full paper and analysis.",
      path: "/validation/preprint",
      date: ARTICLE_DATES.PREPRINT,
      icon: ClipboardCheck,
      category: 'validation'
    },
    {
      title: "Eric Topol Highlights a2z's Multimodal Advantages at RSNA 2024",
      description: "Dr. Eric Topol showcases a2z Radiology AI's advanced capabilities in multimodal AI and grounded reporting, highlighting our technological advantages in the field.",
      path: "/news/rsna-2024-topol-mention",
      date: ARTICLE_DATES.RSNA_MENTION,
      icon: Newspaper,
      category: 'news'
    },
    {
      title: "a2z Radiology AI Mentioned in Financial Times",
      description: "Our co-founder Dr. Pranav Rajpurkar discusses the future of AI in healthcare and our mission to eliminate medical errors.",
      path: "/news/financial-times-mention",
      date: ARTICLE_DATES.FT_MENTION,
      icon: Newspaper,
      category: 'news'
    },
    {
      title: "Analysis of High-Confidence AI Predictions",
      description: "Explore how our model identifies critical findings that were initially overlooked in radiologist reports.",
      path: "/validation/miss-analysis",
      date: ARTICLE_DATES.MISS_ANALYSIS,
      icon: ClipboardCheck,
      category: 'validation'
    },
    {
      title: "Subgroup Analysis: Validation Across Diverse Scenarios",
      description: "Comprehensive analysis showing consistent performance across different patient groups and imaging protocols.",
      path: "/validation/subgroup",
      date: ARTICLE_DATES.SUBGROUP_ANALYSIS,
      icon: ClipboardCheck,
      category: 'validation'
    },
    {
      title: "Launching a2z Radiology AI: Vision for a World Where no Disease is Missed",
      description: "a2z Radiology AI emerges from stealth, introducing AI-powered analytics for radiologists with a2z-1 for abdominal-pelvis CT interpretation.",
      path: "/story",
      date: ARTICLE_DATES.A2Z_STORY,
      icon: Newspaper,
      category: 'news'
    }
  ].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  return (
    <section className="relative overflow-hidden py-16">
      <div className="blur-behind">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mb-12">
            <h2 className="gradient-text text-3xl font-bold mb-8">
              Latest News & Validation Studies
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {articles.map((article, index) => {
                const Icon = article.icon;
                return (
                  <Link key={article.path} to={article.path}>
                    <motion.div 
                      className="glass-card p-6 h-full hover-lift"
                      whileHover={{ y: -4 }}
                      transition={{ duration: 0.2 }}
                    >
                      <div className="flex items-start space-x-4">
                        <div className="bg-primary/10 dark:bg-primary/20 p-2 rounded-xl">
                          <Icon className="w-6 h-6 text-primary dark:text-accent flex-shrink-0" />
                        </div>
                        <div>
                          <h3 className="text-xl font-semibold gradient-text mb-2">
                            {article.title}
                          </h3>
                          <p className="text-sm text-gray-500 dark:text-gray-400 mb-3">
                            {formatDate(article.date)}
                          </p>
                          <p className="text-gray-600 dark:text-gray-300">
                            {article.description}
                          </p>
                          <div className="mt-4 gradient-text font-semibold flex items-center">
                            Read More
                            <ArrowRight className="ml-2 h-4 w-4" />
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// Update AnnouncementBanner
const AnnouncementBanner = () => {
  return (
    <div className="bg-white/80 dark:bg-gray-900/80">
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap gap-2">
          <div className="flex items-center flex-1 min-w-0">
            <span className="flex-shrink-0 p-2.5 bg-primary/10 dark:bg-primary/20 rounded-xl">
              <ClipboardCheck className="h-7 w-7 text-primary dark:text-accent" aria-hidden="true" />
            </span>
            <p className="ml-3 font-medium text-primary dark:text-accent truncate text-base sm:text-lg">
              <span className="inline">New: </span>
              <span className="hidden sm:inline">External Validation Study Released - Performance Analysis Across 21 Conditions</span>
              <span className="sm:hidden">External Validation Study Released</span>
            </p>
          </div>
          <div className="flex-shrink-0">
            <Link
              to="/validation/preprint"
              className="bg-primary/10 dark:bg-primary/20 text-primary dark:text-accent font-medium py-2.5 px-5 text-base rounded-lg hover:bg-primary/20 dark:hover:bg-primary/30 transition-colors"
            >
              Read paper
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

// Main Homepage Component
interface HomeProps {
  openCalendly: () => void;
}

const Home: React.FC<HomeProps> = ({ openCalendly }) => {
  // Define navItems to pass to SidebarNav
  const navItems = [
    { name: 'How It Works', to: 'how-it-works' },
    { name: 'Impact', to: 'impact' },
    { name: 'Validation', to: 'validation' },
    { name: 'Latest News', to: 'related-content' },
    { name: 'Join Our Program', to: 'take-the-next-step' },
  ];

  const metadata = generateMetadata({
    title: 'a2z Radiology AI: AI-Powered Analytics for Radiologists',
    description: 'a2z Radiology AI provides AI-powered analytics for radiologists, with a2z-1 for abdominal-pelvis CT interpretation.',
    type: 'website'
  });

  return (
    <div className="min-h-screen">
      <PageMetadata {...metadata} />

      <div className="animate-fade-in">
        <AnnouncementBanner />
      </div>

      <SidebarNav sections={navItems} />

      <div className="lg:mx-4">
        <Element name="home" className="animate-slide-up">
          <HeroSection openCalendly={openCalendly} />
        </Element>

        <Element name="how-it-works" className="animate-slide-up">
          <HowA2z1WorksSection />
        </Element>

        <Element name="impact" className="animate-slide-up">
          <RealWorldImpactSection />
        </Element>

        <Element name="validation" className="animate-slide-up">
          <ScientificValidationSection />
        </Element>

        <Element name="related-content" className="animate-slide-up">
          <RelatedContent />
        </Element>

        <Element name="take-the-next-step" className="animate-slide-up">
          <TakeNextStepSection openCalendly={openCalendly} />
        </Element>
      </div>
    </div>
  );
};

export default Home;
