import React from 'react';
import { Helmet } from 'react-helmet-async';
import { generateMetadata } from '../../metadata/siteMetadata';

interface PageMetadataProps {
  title: string;
  description: string;
  type?: string;
  image?: string;
  publishedDate?: string;
  modifiedDate?: string;
  authors?: Array<{
    name: string;
    url: string;
  }>;
}

export const PageMetadata: React.FC<PageMetadataProps> = ({
  title,
  description,
  type,
  image,
  publishedDate,
  modifiedDate,
  authors
}) => {
  const metadata = generateMetadata({
    title,
    description,
    type,
    image,
    publishedDate,
    modifiedDate,
    authors
  });

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta property="og:title" content={metadata.openGraph.title} />
      <meta property="og:description" content={metadata.openGraph.description} />
      <meta property="og:image" content={metadata.openGraph.image} />
      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.openGraph.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />
      <script type="application/ld+json">
        {JSON.stringify(metadata.jsonLd)}
      </script>
    </Helmet>
  );
}; 