import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Navbar, Footer, ScrollProgressBar } from './components/shared/Shared';
import { ErrorBoundary } from 'react-error-boundary';
import { FaExclamationTriangle } from 'react-icons/fa';
import { PopupModal } from 'react-calendly';
import { HelmetProvider } from 'react-helmet-async';

// News components
import Home from './components/home';
import A2ZRadiologyAIOutOfStealth from './components/news/a2zstory';
import FTMention from './components/news/FTMention';
import RSNAMention from './components/news/RSNAMention';

// Validation components
import A2ZValidation from './components/validation/a2zexternalvalidation';
import A2ZSubgroupAnalysis from './components/validation/a2zsubgroupanalysis';
import HighConfidenceRadiologistMisses from './components/validation/a2zmissanalysis';
import A2Z1Preprint from './components/validation/a2z1preprint';

// Page components
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import NotFound from './components/pages/NotFound';

const ErrorFallback = ({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) => (
  <div className="min-h-screen flex items-center justify-center p-4 bg-gradient-to-br from-white to-gray-50 dark:from-gray-900 dark:to-gray-800">
    <div className="card max-w-lg w-full p-8 text-center">
      <FaExclamationTriangle className="mx-auto h-12 w-12 text-red-500 mb-4" />
      <h1 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">
        Something went wrong
      </h1>
      <p className="text-gray-600 dark:text-gray-400 mb-6">
        {error.message}
      </p>
      <button
        onClick={resetErrorBoundary}
        className="button"
      >
        Try again
      </button>
    </div>
  </div>
);

const App: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(() => {
    // Always default to dark mode
    if (typeof window !== 'undefined') {
      // Set dark mode in localStorage
      localStorage.setItem('theme', 'dark');
      return true;
    }
    return true;
  });
  const { pathname } = useLocation();
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  const openCalendly = () => setIsCalendlyOpen(true);
  const closeCalendly = () => setIsCalendlyOpen(false);

  useEffect(() => {
    const root = document.documentElement;
    if (isDarkMode) {
      root.classList.add('dark');
    } else {
      root.classList.remove('dark');
    }
  }, [isDarkMode]);

  // Smooth scroll to top on route change
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  const toggleTheme = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'light');
  };

  return (
    <HelmetProvider>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => window.location.reload()}
      >
        <div className="flex flex-col min-h-screen bg-gradient-to-br from-white to-gray-50 dark:from-gray-900 dark:to-gray-800">
          <ScrollProgressBar />
          <Navbar openCalendly={openCalendly} />
          <main className="flex-grow">
            <Routes>
              {/* Home route */}
              <Route path="/" element={<Home openCalendly={openCalendly} />} />

              {/* Legacy redirects */}
              <Route path="/a2z1-product-launch" element={<Navigate to="/" replace />} />
              <Route path="/news/a2z1-product-launch" element={<Navigate to="/" replace />} />
              <Route path="/story" element={<Navigate to="/news/a2z-radiology-ai-out-of-stealth" replace />} />
              <Route path="/a2z-radiology-ai-out-of-stealth" element={<Navigate to="/news/a2z-radiology-ai-out-of-stealth" replace />} />
              
              <Route path="/validation" element={<Navigate to="/validation/external" replace />} />
              <Route path="/a2z-1-validation-multi-site-performance-in-abdomen-pelvis-ct" element={<Navigate to="/validation/external" replace />} />
              <Route path="/news/a2z-1-validation-multi-site-performance-in-abdomen-pelvis-ct" element={<Navigate to="/validation/external" replace />} />
              
              <Route path="/subgroup-analysis" element={<Navigate to="/validation/subgroup" replace />} />
              <Route path="/a2z-1-subgroup-analysis-rigorous-validation-across-diverse-clinical-scenarios" element={<Navigate to="/validation/subgroup" replace />} />
              <Route path="/news/a2z-1-subgroup-analysis-rigorous-validation-across-diverse-clinical-scenarios" element={<Navigate to="/validation/subgroup" replace />} />
              
              <Route path="/miss-analysis" element={<Navigate to="/validation/miss-analysis" replace />} />
              <Route path="/discovering-high-confidence-radiologist-misses" element={<Navigate to="/validation/miss-analysis" replace />} />
              <Route path="/news/discovering-high-confidence-radiologist-misses" element={<Navigate to="/validation/miss-analysis" replace />} />
              
              <Route path="/preprint" element={<Navigate to="/validation/preprint" replace />} />
              <Route path="/a2z-1-technical-paper" element={<Navigate to="/validation/preprint" replace />} />
              <Route path="/news/a2z-1-technical-paper" element={<Navigate to="/validation/preprint" replace />} />

              {/* News Routes */}
              <Route path="/news/rsna-2024-topol-mention" element={<RSNAMention openCalendly={openCalendly} />} />
              <Route path="/news/a2z-radiology-ai-out-of-stealth" element={<A2ZRadiologyAIOutOfStealth />} />
              <Route path="/news/financial-times-mention" element={<FTMention openCalendly={openCalendly} />} />

              {/* Validation Study Routes */}
              <Route path="/validation/external" element={<A2ZValidation openCalendly={openCalendly} />} />
              <Route path="/validation/subgroup" element={<A2ZSubgroupAnalysis openCalendly={openCalendly} />} />
              <Route path="/validation/miss-analysis" element={<HighConfidenceRadiologistMisses openCalendly={openCalendly} />} />
              <Route path="/validation/preprint" element={<A2Z1Preprint openCalendly={openCalendly} />} />

              {/* Static Pages */}
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
          <Footer isDarkMode={isDarkMode} toggleTheme={toggleTheme} openCalendly={openCalendly} />

          {/* Calendly Popup Modal */}
          <PopupModal
            url="https://calendly.com/d/cmg5-cyr-q57"
            onModalClose={closeCalendly}
            open={isCalendlyOpen}
            rootElement={document.getElementById('root') as HTMLElement}
            prefill={{}}
            pageSettings={{
              backgroundColor: 'ffffff',
              hideEventTypeDetails: false,
              hideLandingPageDetails: false,
              primaryColor: '4361ee',
              textColor: '1e293b'
            }}
          />

          {/* Add custom modal backdrop */}
          {isCalendlyOpen && (
            <div 
              className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[9998]"
              onClick={closeCalendly}
            />
          )}
        </div>
      </ErrorBoundary>
    </HelmetProvider>
  );
};

export default App;
