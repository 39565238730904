import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Sun, Moon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

// Navbar Component
interface NavbarProps {
  openCalendly: () => void;
}

export const Navbar: React.FC<NavbarProps> = ({ openCalendly }) => {
  const [isOpen, setIsOpen] = useState(false);

  const navItems = [
    { to: "/", text: "Homepage" },
    { to: "/story", text: "The a2z Story" },
    {
      text: "Validation Studies",
      children: [
        { to: "/validation", text: "External Validation" },
        { to: "/subgroup-analysis", text: "Subgroup Analysis" },
        { to: "/miss-analysis", text: "Radiologist Misses Analysis" },
        { to: "/validation/preprint", text: "Clinical Validation Analysis" }
      ]
    },
    {
      text: "News",
      children: [
        { to: "/news/rsna-2024-topol-mention", text: "Eric Topol at RSNA 2024" },
        { to: "/news/financial-times-mention", text: "FT: 'A world where no disease is missed'" }
      ]
    }
  ];

  const renderNavItem = (item: { to?: string; text: string; children?: Array<{ to: string; text: string }> }, isMobile: boolean) => {
    if (item.children) {
      return (
        <div key={item.text} className={`relative group ${isMobile ? "mb-4" : ""}`}>
          <div className="text-gray-700 dark:text-gray-300 hover:text-gray-500 flex items-center gap-1 cursor-pointer">
            {item.text}
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          <div className={`
            ${isMobile
              ? "mt-2 ml-4 space-y-2"
              : "absolute left-0 mt-2 w-56 bg-white dark:bg-dark shadow-lg rounded-md py-2 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200"}
            z-50
          `}>
            {item.children.map((child) => (
              <Link
                key={child.to}
                to={child.to}
                className={`
                  ${isMobile
                    ? "block"
                    : "block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-800"}
                  text-gray-700 dark:text-gray-300 hover:text-gray-500
                `}
                onClick={() => setIsOpen(false)}
              >
                {child.text}
              </Link>
            ))}
          </div>
        </div>
      );
    }

    return item.to ? (
      <Link
        key={item.to}
        to={item.to}
        className={`text-gray-700 dark:text-gray-300 hover:text-gray-500 ${
          isMobile ? "block mb-2" : ""
        }`}
        onClick={() => setIsOpen(false)}
      >
        {item.text}
      </Link>
    ) : null;
  };

  const renderNavItems = (isMobile: boolean) => (
    <>
      {navItems.map((item) => renderNavItem(item, isMobile))}
      <ShinyButton
        onClick={openCalendly}
        className={isMobile ? "w-full text-left" : ""}
      >
        Explore a2z-1
      </ShinyButton>
    </>
  );

  return (
    <nav className="bg-white/80 dark:bg-gray-900/80 backdrop-blur-lg py-4 px-6 sticky top-0 w-full z-50 shadow-lg transition-all duration-300">
      <div className="flex justify-between items-center max-w-7xl mx-auto relative">
        <div className="flex items-center space-x-2">
          <Link to="/" className="transition-transform duration-300 hover:scale-105">
            <img src="/assets/logos/a2z-logo-linear.png" alt="a2z Logo" className="w-48 h-auto" />
          </Link>
        </div>
        <div className="flex items-center lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-gray-700 dark:text-gray-300 hover:text-primary dark:hover:text-accent focus:outline-none transition-colors duration-300"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
              />
            </svg>
          </button>
        </div>
        <div className="hidden lg:flex items-center space-x-6">
          {renderNavItems(false)}
        </div>
      </div>

      {isOpen && (
        <div className="lg:hidden mt-6 border-t border-gray-100 dark:border-gray-800/50 pt-4">
          {renderNavItems(true)}
        </div>
      )}
    </nav>
  );
};

// Footer Component
interface FooterProps {
  isDarkMode: boolean;
  toggleTheme: () => void;
  openCalendly: () => void;
}

interface FooterLink {
  text: string;
  href?: string;
  isHeader: boolean;
}

export const Footer: React.FC<FooterProps> = ({ isDarkMode, toggleTheme, openCalendly }) => {
  const footerLinks: FooterLink[] = [
    { text: 'Product', isHeader: true },
    { text: 'a2z-1', href: '/story', isHeader: false },
    { text: 'The a2z Story', href: '/story', isHeader: false },
    { text: 'Research', isHeader: true },
    { text: 'External Validation', href: '/validation/external', isHeader: false },
    { text: 'Subgroup Analysis', href: '/validation/subgroup', isHeader: false },
    { text: 'Miss Analysis', href: '/validation/miss-analysis', isHeader: false },
    { text: 'Clinical Validation', href: '/validation/preprint', isHeader: false },
    { text: 'News', isHeader: true },
    { text: 'RSNA 2024', href: '/news/rsna-2024-topol-mention', isHeader: false },
    { text: 'Financial Times', href: '/news/financial-times-mention', isHeader: false },
  ];

  const footerSections = [
    footerLinks.slice(0, 3),  // Product section
    footerLinks.slice(3, 8),  // Research section
    footerLinks.slice(8)      // News section
  ];

  return (
    <footer className="bg-white/80 dark:bg-gray-900/80 backdrop-blur-lg border-t border-gray-200/50 dark:border-gray-800/50 transition-all duration-300">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Logo and Location */}
          <div className="col-span-1">
            <img src="/assets/logos/a2z-logo-linear.png" alt="a2z Logo" className="h-8 w-auto mb-6 transition-transform duration-300 hover:scale-105" />
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-6 font-medium">
              Boston, MA
            </p>
            <button 
              onClick={toggleTheme} 
              className="text-gray-500 hover:text-primary dark:text-gray-400 dark:hover:text-accent transition-all duration-300 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800"
              aria-label="Toggle theme"
            >
              {isDarkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
            </button>
          </div>

          {/* Navigation Sections */}
          {footerSections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="col-span-1">
              <div className="space-y-4">
                {section.map((link, linkIndex) => (
                  <div key={linkIndex}>
                    {link.isHeader ? (
                      <h3 className="text-sm font-bold text-gray-900 dark:text-gray-100 uppercase tracking-wider mb-4">
                        {link.text}
                      </h3>
                    ) : (
                      <Link
                        to={link.href!}
                        className="text-base text-gray-600 dark:text-gray-400 hover:text-primary dark:hover:text-accent block transition-all duration-300 transform hover:translate-x-1"
                      >
                        {link.text}
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Important Notice */}
        <div className="mt-16 pt-8 border-t border-gray-200/50 dark:border-gray-800/50">
          <p className="text-sm text-gray-600 dark:text-gray-400 max-w-3xl leading-relaxed">
            a2z-1 is intended for research, educational, and analytical purposes. It is not approved for clinical or diagnostic use by the FDA in the United States. Availability and any clinical application outside the USA are subject to local regulations and guidelines.
          </p>
        </div>

        {/* Copyright and Additional Links */}
        <div className="mt-8 pt-8 border-t border-gray-200/50 dark:border-gray-800/50">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <p className="text-sm text-gray-600 dark:text-gray-400 font-medium">
              &copy; {new Date().getFullYear()} a2z Radiology AI. All rights reserved.
            </p>
            <ShinyButton onClick={openCalendly} className="!py-2.5 !text-base !px-8">
              Partner With Us
            </ShinyButton>
          </div>
        </div>
      </div>
    </footer>
  );
};

// SidebarNav Component
interface SidebarNavProps {
  sections: { name: string; to: string }[];
}

export const SidebarNav: React.FC<SidebarNavProps> = ({ sections }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  React.useEffect(() => {
    const handleScroll = () => {
      const homeSection = document.getElementById('home');
      if (homeSection) {
        const homeSectionBottom = homeSection.offsetTop + homeSection.offsetHeight;
        setIsVisible(window.scrollY > homeSectionBottom - 100);
      } else {
        setIsVisible(false);
      }

      // Update active section based on scroll position
      sections.forEach(({ to }) => {
        const element = document.getElementById(to);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 150 && rect.bottom >= 150) {
            setActiveSection(to);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, [sections]);

  if (!isVisible) return null;

  return (
    <nav className="hidden lg:block fixed left-0 top-1/2 transform -translate-y-1/2 z-50 ml-2">
      <div className="relative">
        <div className="bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm rounded-lg shadow-lg py-2 px-1">
          <ul className="space-y-1">
            {sections.map(({ name, to }) => (
              <li key={to}>
                <ScrollLink
                  to={to}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={100}
                  isDynamic={true}
                  className={`text-sm transition-all block py-1.5 px-2 rounded-md cursor-pointer whitespace-nowrap
                    ${activeSection === to 
                      ? 'bg-primary/10 dark:bg-primary/20 text-primary dark:text-accent font-semibold'
                      : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700/50'
                    }`}
                  onSetActive={() => setActiveSection(to)}
                >
                  {name}
                </ScrollLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

// ScrollProgressBar Component
export const ScrollProgressBar: React.FC = () => {
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const totalScroll =
        document.documentElement.scrollHeight - window.innerHeight;
      const currentScroll = window.scrollY;
      const scrollPercentage = (currentScroll / totalScroll) * 100;
      setScrollProgress(scrollPercentage);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <motion.div
      className="fixed top-0 left-0 right-0 h-1 bg-primary dark:bg-secondary z-50"
      style={{ scaleX: scrollProgress / 100 }}
    />
  );
};

// ShinyButton Component
interface ShinyButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
}

export const ShinyButton: React.FC<ShinyButtonProps> = ({ onClick, children, className = '' }) => (
  <motion.button
    className={`relative overflow-hidden bg-gradient-to-r from-primary to-primary/90 dark:from-accent dark:to-accent/90 text-white font-bold py-3 px-6 text-lg rounded-lg shadow-lg hover:shadow-xl transition-all flex items-center shine-effect ${className}`}
    onClick={onClick}
    whileHover={{ scale: 1.02, y: -2 }}
    whileTap={{ scale: 0.98 }}
  >
    <div className="flex items-center relative z-10">
      {children}
      <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
    </div>
  </motion.button>
);

export { default as AuthorSection } from './AuthorSection';
